import logo from './logo.svg';
import { ChakraProvider } from '@chakra-ui/react'
// import './App.css';
import {theme} from './components/utils/theme'
// import Index from './components/Layouts/Header';
import Homepage from './components/Pages/Homepage';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Airdrop from './components/Pages/airdrop';
import Wallet from './contexts/WalletCtx';
import Nft from './components/Pages/nft';
import Presale from './components/Pages/presale';

// 1. import `ChakraProvider` component

function App() {
  // 2. Wrap ChakraProvider at the root of your app
  return (
      <Wallet>
      <Router>
        <Switch>
        <Route path='/presale'>
            <Presale />
          </Route>
        <Route path='/airdrop'>
            <Airdrop />
          </Route>
        <Route path='/nft'>
            <Nft />
          </Route>
          <Route path='/'>
            <Homepage />
          </Route>
        </Switch>
      </Router>
      </Wallet>
  )
}

export default App;
