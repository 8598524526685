import { Box, Text, useToast,Flex } from "@chakra-ui/react"
import { request } from "../components/utils/request"
import { useConnection, useWallet } from "@solana/wallet-adapter-react"
import { PublicKey, Transaction } from "@solana/web3.js"
import { useEffect, useState } from "react"
import { CloseIcon } from "@chakra-ui/icons"
import { useCustomToast } from "./customtoast"

export const useMintNFTs =()=>{
    const {publicKey,signTransaction} = useWallet()
    const [tokenTotalSupply,setSupply] = useState(0)
    const {connection} = useConnection()
    const [isLoading,setisLoading] = useState(false)
    const {toast} = useCustomToast()
    const mintNfts=async(amount)=>{
        setisLoading(true)
        try{
            const mint =await request.get("/nft/serializedtx",{params:{
                walletAddress:publicKey.toBase58(),
                amount:parseInt(amount)
            }})
            const tx = Transaction.from(Buffer.from(mint.data.tx, "base64"));
      const signedTx = await signTransaction(tx);
      await connection.sendRawTransaction(signedTx.serialize());
      await request.post("/nft",{walletAddress:publicKey.toBase58(),amount:parseInt(amount)})
      await getTokenTS()
            toast({
                description:`You have minted ${amount} NFTs`
            })
        }catch(e){
            toast({
                description:e?.response?.data?.msg || "An unknown error occured",
                status:2,
            })
        }
        setisLoading(false)
    }
    const getTokenTS=async()=>{
        try {
            const mainnetPubkey = "3nD7B6DAnns8vx5618YZQmgZSKB5QuFpv8QXVg2L72EY"
            const devnetPubkey = "5yUSqjSQN76JJzkmR6zDnAC4h9JLBVZJZHe1rKE3fMuF"
            const solana =await connection.getTokenSupply(new PublicKey(mainnetPubkey));
            setSupply(solana.value.uiAmount)
        } catch (error) {
            console.error(error)
        }
    
    }
useEffect(()=>{
    getTokenTS()
},[])
    return {tokenTotalSupply,mintNfts,isLoading}
}