import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import Chart from 'react-google-charts'
function Tokenomics() {
  const data=[
    ["Task","Amount"],
    [
       "Burn ",
       5000000000 + 500000000
    ], 
    [  "Give ways, rewards and marketing",
       1000000000
    ],
    [
       "Token presale ",
      1500000000
    ],
    [
       "Airdrop ",
      500000000
    ],
    [
      "TOKEN LISTING ",
       1500000000
    ]
  ]
   const options = {
    // title: "My Daily Activities",
    is3D: true,
    backgroundColor:'transparent',
    color:'#fff'
  };
  return (
    <Box minH='50vh' mt='10' id='tokenomics'>
        <Text textAlign='center' variant={'secondSectionHeader'} fontWeight='bold'>TOKENOMICS</Text>
        <Chart chartType="PieChart"
      data={data}
      options={options}
      className='googleChart'
      style={{background:'transparent',color:'#fff'}}
      width={"100%"}
      height={"400px"}   />
          <Text textAlign='center'>MAXIMUM TOTAL SUPPLY: $10,000,000,000 SAMOWOLF</Text>
    </Box>
  )
}

export default Tokenomics