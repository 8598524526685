import React, { useEffect, useState } from "react";
import Index from "../../Layouts/Header";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Box, Button, Flex, Input, InputGroup, Text, useToast } from "@chakra-ui/react";
import { request } from "../../utils/request";
import { oauth_url } from "../../utils/discord_utils";
import { goto } from "../../utils/helpers";
import { useWallet } from "@solana/wallet-adapter-react";
function Airdrop() {
  const [userName, setUserName] = useState("");
  const [twitterUserName, settwitterUserName] = useState("");
  const [retweetLink, setretweetLink] = useState("");
  const [refferer, setRefferer] = useState("");
  const [isLoading,setIsLoading] = useState(false);
  const {publicKey,connected} = useWallet()
  const toast = useToast()
  const searchParams = new URLSearchParams(window.location.search);

  const code = searchParams.get("code");
  const refId = searchParams.get("refid")
  const submitForm = async()=>{
    setIsLoading(true)
    try {
      const walletAddress = publicKey.toBase58()
      const discordUserName = userName;
      const submit = await request.post("/airdrop",{discordUserName,walletAddress,twitterUserName,retweetLink,refferer})
      console.log(submit) 
    toast({
      position: 'bottom',
      render: () => (
        <Box color='white' borderRadius="10px" p={3} bg='green.500'>
          <Text>Airdrop form Submitted</Text>
          <Text>You have successfully submitted this airdrop form</Text>
        </Box>
      ),})
    } catch (error) {
  toast({
    position: 'bottom',
    render: () => (
      <Box color='white' borderRadius="10px" p={3} bg='red.500'>
        <Text>An error occured</Text>
        <Text>{error?.response?.data?.msg || "An error occured, try again"}</Text>
      </Box>
    ),})
      setIsLoading(false)
    }
    setIsLoading(false)
  }
  useEffect(()=>{
    if(refId){
      setRefferer(refId)
    }
  },[refId])
  useEffect(() => {
    if (code) {
      (async () => {
        try {
          const user_data = await request.get("/discord", {
            params: { code },
          });
          console.log({ user_data });
          setUserName(user_data.data.username);
          window.localStorage.setItem(
            "userDetails",
            JSON.stringify(user_data.data)
          );
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [code]);
  return (
    <Flex flexDir="column" w="100vw" alignItems="center">
      <Index />
      <Flex
        border="1px solid #fff"
        w={["95vw", "95vw", "50vw"]}
        gap="5"
        p="5"
        borderRadius="10px"
        flexDir="column"
        alignItems="center"
      >
        <Text fontWeight="bold" fontFamily="cursive" fontSize="1.5em">
          AIRDROP !!! 
        </Text>
        {/* <Text fontWeight="bold" fontFamily="cursive" fontSize="1.5em">
          AIRDROP FORM
        </Text> */}
        {/* <WalletMultiButton />
        <Text>Join our <a style={{color:"blue"}} target="_blank" href="https://t.co/LZ0c0l6uWq">discord</a></Text>
        <Button
          onClick={(e) => goto(oauth_url)}
          _hover={{ bgColor: "blue.300" }}
          p="3"
          borderRadius="10px"
          bgColor="blue.500"
        >
          {code ? `${userName} CONNECTED` : "CONNECT DISCORD"}
        </Button>

        <Box display="flex" flexDir="column" w="100%">
          <label htmlFor="twitterUsername">Twitter username, follow <a style={{color:"blue"}} target="_blank" href="https://x.com/wolf_samo">our twitter</a></label>
          <Input
            borderRadius="10px"
            border="1px dashed #fff"
            bg="transparent"
            color="#fff"
            h="30px"
            p="3"
            id="twitterUsername"
            onChange={e=>settwitterUserName(e.target.value)}
          />
        </Box>
        <Box display="flex" flexDir="column" w="100%">
          <label htmlFor="twitterRETWEETLINK">Twitter retweet link of our pinned post, <a style={{color:"blue"}} target="_blank" href="https://x.com/wolf_samo">click here</a></label>
          <Input
          type="url"
            borderRadius="10px"
            border="1px dashed #fff"
            bg="transparent"
            color="#fff"
            h="30px"
            p="3"
            id="twitterRETWEETLINK"
            onChange={e=>setretweetLink(e.target.value)}
          />
        </Box>
        <Box display="flex" flexDir="column" w="100%">
          <label htmlFor="referrer">Reffer twitter Username(optional)</label>
          <Input
          type="url"
            borderRadius="10px"
            border="1px dashed #fff"
            bg="transparent"
            color="#fff"
            h="30px"
            p="3"
            value={refferer}
            id="referrer"
            onChange={e=>setRefferer(e.target.value)}
          />
        </Box>
        <Button
          border={"1px dashed #fff"}
          borderRadius="10px"
          p="5"
          disabled={!userName || !connected}
          onClick={submitForm}
          isLoading={isLoading}
          _hover={{ bg: "#fff", color: "#000" }}
        >
          SUBMIT
        </Button> */}
      </Flex>
    </Flex>
  );
}

export default Airdrop;
