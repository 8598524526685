import { Box, Image, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import flyer1 from '../../../assets/img-3.png'
function SecondSec() {
  const [isLargerThan500px] = useMediaQuery('(min-width:500px)')
  return (
    <Box w='100%' minH='100vh' id='about' pt='10' display='flex' justifyContent='space-between' flexDir={['column','column','row']} overflowX='hidden'>
     {isLargerThan500px && <Box w={['100%','100%','']} justifyContent={['center','center','flex-start']} flex='1' pl={['','','10']} display='flex' alignItems='center'>
        <Image src={flyer1}/>
      </Box>}
      <Box flex='1' w={['100%','100%','']} pr={['5','5','10']} pl={!isLargerThan500px && ['5','5','10']} display='flex' flexDir='column'  gap='10'>
        <Text variant='secondSectionHeader' textAlign='center'>ABOUT THE SAMOWOLF TOKEN</Text>
        {!isLargerThan500px && <Box w={['100%','100%','']} justifyContent={['center','center','flex-start']} flex='1' pl={['','','10']} display='flex' alignItems='center'>
        <Image src={flyer1}/>
      </Box>}
        <Text variant='secondSectionBody' wordBreak='break-word'>At SamoWolf, we believe in the power of memes to bring people together and inspire creativity.
           That's why we've created a memecoin that is not only fun and easy to use, but also built on cutting-edge blockchain technology.
           </Text>
           <Text variant='secondSectionBody'>
           The Solana blockchain provides lightning-fast transactions, making it the perfect choice for our memecoin. Plus, 
           its decentralized nature ensures that your transactions are secure and private.
           </Text>
           <Text variant='secondSectionBody'>
           Our team is composed of experienced blockchain developers, meme enthusiasts and community builders. We are dedicated to creating a vibrant and growing community around SamoWolf,
            and we're constantly working on new features and partnerships to make our memecoin even more fun and valuable.
           </Text>
           <Text variant='secondSectionBody'>
           Thank you for choosing SamoWolf. We can't wait to see the memes you create and share using our coin. Let's make the internet a funnier and more connected place together.
           </Text>
      </Box>
    </Box>
  )
}

export default SecondSec