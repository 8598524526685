import { defineStyleConfig } from '@chakra-ui/react'

export default defineStyleConfig({

  // Styles for the visual style variations
  variants: {
    sectionHeaders:{
        fontSize:['1.4em','2em','2.3em','5em'],
        fontWeight:['bold'],
        lineHeight:'1'
    },
    sectionBody:{
        fontSize:['1.2em','1.5em'],
        fontWeight:['normal','normal','bold'],
        lineHeight:'1'
    },
    secondSectionBody:{
        fontSize:'1.2em',
        textAlign:['center','center','left']
    },
    secondSectionHeader:{
        fontSize:['1.4em','2em','2.5em'],
        fontWeight:'bold',
    }
  },
})