import { Flex } from '@chakra-ui/react'
import FirstSec from '../../Layouts/Body/FirstSec/Index'
import Roadmap from '../../Layouts/Body/Roadmap/Index'
import SecondSec from '../../Layouts/Body/SecondSec'
import ThirdSec from '../../Layouts/Body/ThirdSec.js'
import Tokenomics from '../../Layouts/Body/Tokenomics'

export default function HomepageArranged(){
    return <Flex w='100vw' overflowX='hidden' flexDir='column'>
    <FirstSec />
    <SecondSec />
    <ThirdSec />
    <Roadmap />
    <Tokenomics />
    </Flex>
}