import { Box, Flex, Image, Link, Text, useColorMode, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import logo from '../../../assets/img-4.png'
import fontFam1 from '../../../assets/Share Tech Mono_Regular.json'
import BorderBg from '../../../utils/Useables/BorderBg'
import SecondSec from '../SecondSec'
import styles from './firstsec.module.css'
import { Link as Clink, animateScroll as scroll } from "react-scroll";
function FirstSec() {
  const {colorMode} = useColorMode()
  const isLight = colorMode === 'light'
  const [isLargerThan500px] = useMediaQuery('(min-width:500px)')
  const linkBehaviour = {
  spy:true,
  smooth:true,
  offset:-70,
  duration:500,
}
  return (
    <Box overflow='hidden' id='home'  minH='50vh' w='100%'  display='flex' 
    flexDirection={['column-reverse','column-reverse','row']}
    //  borderTop='2px dashed' borderColor="#fff"
     >
        <Box  w={['100%','100%','50%']} textAlign={['center','center','left']} pl={['5','5','10']} pr={!isLargerThan500px && ['5','5','10']}   alignItems={['center','center','flex-start']}  pt={['5','5','20']} display='flex' justifyContent={['center','center','center','flex-start']} flexDir='column' gap={['5','5','10']}>
            <Text variant={'sectionHeaders'}>Welcome to SamoWolf!</Text>
            <Text variant='sectionBody'>The revolutionary new memecoin built on the Solana blockchain.</Text>
            <Text variant='sectionBody'>Unlock the full potential of the crypto world with us as your expert guide!</Text>
            <Clink to='howtobuy' {...linkBehaviour}>
            <BorderBg height='max-content' width='max-content'>
            <Flex  cursor='pointer' bg={isLight ?'rgb(255,255,255)':'rgb(0,0,0)'} borderRadius='50px' w={['200px','200px','250px']} alignItems='center' gap='2'>
              <Image className={styles.rotateComponent} src={logo} h={['50px']} w={['50px']} borderRadius='50%'/>
              <Text fontSize={['1.2em','1.2em','1.5em']}>Get $SamoWolf!</Text>
            </Flex>
            </BorderBg>
            </Clink>
        </Box>
        <Box w={['100%','100%','50%']} pr={['','','10']}  alignItems='center'  overflow='hidden' display='flex' justifyContent={['center','center','flex-end']}>
            <Image  className={styles.rotateComponent} src={logo} />
        </Box>
    </Box>
  )
}

export default FirstSec