import { Box, useColorMode } from '@chakra-ui/react'
import React from 'react'
import bgimg from '../assets/moon.png'
function Background() {
    const {colorMode} = useColorMode()
    const isLightMode = colorMode === 'light'
  return (
    <>
    <Box position='absolute' backgroundRepeat='no-repeat' backgroundPosition='center' backgroundSize='cover' zIndex='-1' inset='0' overflowX='hidden' minH='100vh' w='100vw' bgImage={bgimg}></Box>
    <Box position='absolute' zIndex='-1' bg={isLightMode ? 'rgba(255,255,255,0.8)':'rgba(0,0,0,0.8)'} backdropFilter='blur(2px)' overflowX='hidden' inset='0' minH='100vh' w='100vw'/>
    </>
  )
}

export default Background