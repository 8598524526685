import { IconButton,Link,Box } from '@chakra-ui/react'
import React from 'react'
import {FaDiscord, FaTwitter} from 'react-icons/fa'
function Sidebar() {
  return (
    <Box display='flex' backdropFilter='blur(10px)' borderRadius='10px' position='fixed' justifyContent='center' gap='10' top='40%' right='2%' alignItems='center' flexDir='column' h='200px' w='50px' >
        <Link _hover={{scale:1.2,border:'1px solid #5e33d2',padding:'2', borderRadius:'50%'}} href='https://twitter.com/wolf_samo'><FaTwitter color='#fff' size='1.5em'/></Link>
        <Link _hover={{scale:1.2,border:'1px solid #5e33d2',padding:'2', borderRadius:'50%'}} href='https://discord.com/invite/JX69ctTYD3'><FaDiscord color='#fff' size='1.5em'/></Link>
    </Box>
  )
}

export default Sidebar