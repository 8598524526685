import { Box, Center, OrbitControls, PerspectiveCamera, RoundedBox, Text3D } from '@react-three/drei'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import * as THREE from 'three';
import img from '../../assets/pagelogo.png'
import imgAsset from "../../assets/SAMOWOLFY.png"
import React, { Suspense, useRef } from 'react'
import font from '../../assets/Poppins Medium_Regular.json'
import { useMediaQuery } from '@chakra-ui/react';

function CustomBox(){
    const load = useLoader(THREE.TextureLoader,img)
    const ref = useRef()
    useFrame((_, delta) => {
        ref.current.rotation.x += 1 * delta
        ref.current.rotation.y += 0.5 * delta
      })
    return <RoundedBox ref={ref} radius={0.2}>
        <meshStandardMaterial map={load}/>
    </RoundedBox>
}
function LogoText({text}){
    const load = useLoader(THREE.TextureLoader,text ? imgAsset : img)
    const ref = useRef()
    // useFrame((_, delta) => {
    //     ref.current.rotation.x += 1 * delta
    //     // ref.current.rotation.y += 0.5 * delta
    //   })
    
    return <Text3D   ref={ref} font={font} >
        {text || "SAMOWOLF"}
        <meshStandardMaterial map={load}/>
    </Text3D>
}
export function LogoText3d({text,mwidth,dWidth,mzIndex,dzIndex,height}){
  const [isLargerThan400] = useMediaQuery('(min-width:400px)')
  const w = isLargerThan400 ? dWidth||'200px' : mwidth || '150px'
  const z = isLargerThan400 ? dzIndex||2.3 : mzIndex||3
    return <Canvas style={{height:height || '50px',width:w}}>
        <ambientLight  intensity={0.5} />
      <directionalLight />
      <PerspectiveCamera makeDefault position={[0,0,z]}/>
      <Center>
      <LogoText text={text}/>
      </Center>
    </Canvas>
}

function BookBox(){
  const load = useLoader(THREE.TextureLoader,imgAsset)
  const ref = useRef()
  useFrame((_, delta) => {
      ref.current.rotation.x += 0.05 * delta
      ref.current.rotation.y += 0.05 * delta
    })
  return <RoundedBox ref={ref} radius={0.02}>
      <meshStandardMaterial map={load}/>
  </RoundedBox>
}
export function Image3D(){
  const [isLargerThan400] = useMediaQuery('(min-width:400px)')
  const h = isLargerThan400 ? '500px' : '250px'
  const w = isLargerThan400 ? '400px' : '300px'
  return <Canvas style={{height:h,width:w}}>
  <ambientLight  intensity={0.5} />
<directionalLight />
<PerspectiveCamera makeDefault position={[0,0,2.6]}/>
<Center>
<BookBox  />
</Center>
<OrbitControls />
</Canvas>
}
function Logo() {
  const [isLargerThan400] = useMediaQuery('(min-width:400px)')
  const h = isLargerThan400 ? '50px' : '45px'
  return (
    <Canvas  style={{height:h,width:h}}>
        <ambientLight  intensity={0.5} />
      <directionalLight />
      <PerspectiveCamera makeDefault position={[0,0,1.6]}/>
      <Suspense fallback={null}>
        <CustomBox />
      </Suspense>
        {/* <OrbitControls /> */}
    </Canvas>
  )
}



export default Logo