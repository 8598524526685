import { CloseIcon } from "@chakra-ui/icons"
import { Box, Flex, Text, useToast } from "@chakra-ui/react"

export const useCustomToast = ()=>{
    const makeToast = useToast()
    const toast =({title,description,status})=>{
        if(status === 2){
            makeToast({
                position: 'bottom',
                duration:3600,
                render: () => (
                  <Box color='white' borderRadius="10px" p={3} bg='red.500'>
                    <Flex w="100%" alignItems='center' justifyContent="space-between">
                    <Text>{title||"An error occured"}</Text>
                    <CloseIcon cursor="pointer" onClick={e=>makeToast.closeAll()}/>
                    </Flex>
                    <Text>{description || "An error occured, try again"}</Text>
                  </Box>
                ),})
            }
            if(status === 1){
            makeToast({
                position: 'bottom',
                duration:3600,
                render: () => (
                  <Box color='white' borderRadius="10px" p={3} bg='green.500'>
                    <Flex w="100%" alignItems='center' justifyContent="space-between">
                    <Text>{title||"Success!!!"}</Text>
                    <CloseIcon cursor="pointer" onClick={e=>makeToast.closeAll()}/>
                    </Flex>
                    {description && <Text>{description || ""}</Text>}
                  </Box>
                ),})

        }
    }

    return {toast}
}