import { Box,Link,Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'

function ThirdSec() {
  const [isLargerThan500px] = useMediaQuery('(min-width:500px)')
  return (
    <Box w='100%' minH='20vh' id='howtobuy' display='flex' pr={['5','5','10']} pl={!isLargerThan500px && ['5','5','10']} pt={['10','10','']} justifyContent='space-between' overflowX='hidden' flexDir='column' alignItems='center' textAlign='center'>
        <Text variant='secondSectionHeader' >HOW TO BUY SAMOWOLF</Text>
        <Text variant='secondSectionBody'>SamoWolf has not been listed on any market yet and the SamoWolf airdrop Shall be announced soon.</Text>
        <Text variant='secondSectionBody'>Ensure you follow our <Link color='#0000ff' href='https://discord.com/invite/JX69ctTYD3'>Discord</Link> and <Link color='#0000ff' href='https://twitter.com/wolf_samo'>Twitter</Link> Channels to participate in the tasks for the Airdrop.</Text>
    </Box>
  )
}

export default ThirdSec