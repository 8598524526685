import {
  Box,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Flex,
  Text,
  Input,
  Button,
  useMediaQuery,
  useToast,
  Link,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useCustomToast } from "../../../hooks/customtoast";
import { request } from "../../utils/request";
import { LogoText3d } from "../../Layouts/Header/Logo";
import Index from "../../Layouts/Header";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";

function Presale() {
  const { toast } = useCustomToast();
  const [presaleData, setPresaledata] = useState([]);
  const [totalSolSpent, settotalSolSpent] = useState(0);
  const [userWallet, setUserWallet] = useState();
  const [isCheckingWallet,setIsChecking] = useState(false)
  const makeToast = useToast()
  const [isLargerThan500px] = useMediaQuery("(min-width:500px)")
  const shortenTxHash = (hash)=>`${hash.slice(0,5)}...${hash.slice(-4)}`
  const presaleAddr = 'AcJfGUbc2iicDbg5PvW6WyGkyL8TWJxAZwMXdDH9mr8h'
  const formatNumber =(val) => new Intl.NumberFormat("us",{currency:"USD"}).format(val).replace("$","")
  const getPresale = async () => {
    setIsChecking(true)
    try {
      if(!userWallet || userWallet === "") throw new Error("User Wallet input can not be empty")
      const getData = await request.get("/presale", {
        params: {
          userWallet,
        },
      });
      console.log(getData.data)
      setPresaledata(val=>getData.data.data);
      settotalSolSpent(val=>parseFloat(getData.data.totalBought/LAMPORTS_PER_SOL).toFixed(3))
    } catch (error) {
      toast({
        title: "Unable to get your presale data",
        description:
          error?.message||error?.response?.data || "Unable to get your presale details",
        status: 2,
      });
    }
    setIsChecking(false)
  };
  return (
    <>
      <Index />
      <Box w="100vw" minH="80vh" p="5">
        <Flex w="100%" alignItems="center" flexDir="column">
          <LogoText3d
            mwidth={"300px"}
            mzIndex={5}
            dzIndex={2.6}
            dWidth={"800px"}
            height={"100px"}
            text={`SAMOWOLF${isLargerThan500px? " TOKEN " :" "}PRESALE`}
          />
          <Flex flexDir="column" w="100%" border="1px dashed #f6f6f6" borderRadius="10px" p="2">
            <Text>Hard cap: 1000 SOL</Text>
            <Text>SOFT cap: 300 SOL</Text>
            <Text>Minimum Purchase: 0.01 SOL</Text>
            <Text>Maximum Purchase: 10 SOL</Text>
            <Text>Total tokens to be sold: 1,5000,000,000 SAMOWOLF</Text>
            <Text>Presale Method: Send SOL to {presaleAddr}</Text>
            <Button onClick={async e=>{
              try {
               await window.navigator.clipboard.writeText(presaleAddr);
                makeToast({
                  title:"Coppied!!!",
                  status:"success",
                  isCloseable:true
                })
              } catch (error) {
                makeToast({
                  title:"An error occured",
                  status:"error",
                  isCloseable:true
                })
                
              }
              }}>COPY PRESALE ADDRESS</Button>
          </Flex>
          <Flex border="1px dashed #f6f6f6" p="2" mt="5" borderRadius="10px" alignItems="center" flexDir={["column","column","row"]} w="100%">
            <Flex gap="2" flexDir="column" w={["100%","100%","50%"]} p="2">
                <Text>Address to check</Text>
                <Input onChange={e=>setUserWallet(e?.target.value)}/>
                <Button isLoading={isCheckingWallet}
                onClick={getPresale}>CHECK</Button>
            </Flex>
            <Flex alignItems={["flex-start","flex-start","flex-end"]} w={["100%","100%","50%"]} flexDir="column">
                <Text fontSize={["0.9em","0.9em","1.2em"]}>Total Sol Sent: {totalSolSpent} SOL</Text>
                <Text fontSize={["0.9em","0.9em","1.2em"]}>Total SamoWolf aquired: {formatNumber(parseFloat(totalSolSpent/0.00000067).toFixed(3))} SAMOWOLF</Text>
            </Flex>
          </Flex>
        </Flex>
        <Text>Note: Our Calculation is based on the Hard Cap</Text>

        <TableContainer w="100%">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Tx hash</Th>
                <Th>SOL AMOUNT</Th>
                <Th>SAMOWOLF ACQUIRED</Th>
              </Tr>
            </Thead>
            <Tbody>
              {presaleData.length>0 ? presaleData.map((val,i)=><Tr key={"presale"+i}>
                <Td>
                  <Link _hover={{color:"blue"}} target="_blank" href={"https://solscan.io/tx/"+val.transactionSignature}>
                  {isLargerThan500px ? val.transactionSignature : shortenTxHash(val.transactionSignature)}
                  </Link>
                  </Td>
                <Td>{val.parsedSolAmount}</Td>
                <Td >{formatNumber(parseFloat(val.parsedSolAmount / 0.00000067).toFixed(3))}</Td>
              </Tr>) : <Text>No presale found, send minium of 0.01SOL to {presaleAddr} to enter SamoWolf Presale</Text>}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default Presale;
