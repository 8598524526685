import { Box, IconButton, Link, Text, Button, useColorMode, Flex, useMediaQuery, Menu, MenuButton, MenuList, MenuItem} from '@chakra-ui/react'
import React from 'react'
import { CloseIcon, HamburgerIcon, MoonIcon,SunIcon } from '@chakra-ui/icons'
import Logo, { LogoText3d } from './Logo'
import { Link as Clink, animateScroll as scroll } from "react-scroll";
// import { Link as Rlink} from "react-router-dom";
import Mobileheader from './Mobile'
function Index() {
    const {colorMode,setColorMode} = useColorMode()
    const [isLargerThan1000] = useMediaQuery('(min-width:1000px)')
    const linkBehaviour = {
      activeClass:"linkActive",
    spy:true,
    smooth:true,
    offset:-70,
    duration:500,
}
  return (
    <>
    {isLargerThan1000 && <Flex backdropFilter='blur(10px)' overflowY='hidden'  position='sticky' top='0' zIndex='100' overflowX='hidden' alignItems='center' justifyContent='space-between' pt='4' pb='5' pl={['5','5','10']} pr={['5','5','10']} w='100vw' minH='10h' h='max-content' bg='blackCol'>
        <Flex   alignItems='center' h='100%'>
            <Logo />
            <LogoText3d />
            {/* <Text fontSize='1.5em' color='300' fontWeight='800'>SAMOWOLF</Text> */}
        </Flex>
         <Flex gap='5' alignItems='center'>
            <Link cursor='pointer'  color='headerText'><Clink to='home' {...linkBehaviour}>Home</Clink></Link>
            <Link cursor='pointer'  color='headerText'><Clink href='https://app.samowolf.xyz' {...linkBehaviour}>APPs</Clink></Link>
            <Link cursor='pointer'  color='headerText'><Clink to='about' {...linkBehaviour}>About</Clink></Link>
            <Link cursor='pointer'  color='headerText'><Clink to='roadmap' {...linkBehaviour}>Roadmap</Clink></Link>
            <Link cursor='pointer'  color='headerText'><Clink to='tokenomics' {...linkBehaviour}>Tokenomics</Clink></Link>
            <Link cursor='pointer' href='/airdrop'  color='headerText'>Airdrop</Link>
            <Link cursor='pointer' href='/nft'  color='headerText'>Nft</Link>
            <Link cursor='pointer' href='/presale'  color='headerText'>Presale</Link>
            <Link border='1px solid'  p='2' borderRadius='10px' cursor='pointer'  color='headerText'><Clink to='howtobuy' {...linkBehaviour}>Buy $SamoWolf</Clink></Link>
            <IconButton as={Button} onClick={e=>setColorMode(colorMode === 'light' ? 'dark' : 'light')} bg='transparent' borderRadius='50%' border='1px solid #fff'
            icon={colorMode === 'light' ? <MoonIcon bg='transparent' color='#000'/> : <SunIcon color='#fff'/>}/>
        </Flex>
        {/* {!isLargerThan1000 && 
        <Flex border='2px solid #00ff00'  alignItems='center' justifyContent='space-between' gap='2' h='100%'>
        <IconButton as={Button} onClick={e=>setColorMode(colorMode === 'light' ? 'dark' : 'light')} bg='transparent' borderRadius='50%' border='1px solid #fff'
            icon={colorMode === 'light' ? <MoonIcon bg='transparent' color='#000'/> : <SunIcon color='#fff'/>}/>
        <Menu>
           {({ isOpen }) => (
    <>
      <MenuButton as={IconButton} isActive={isOpen} icon={isOpen ? <CloseIcon /> : <HamburgerIcon />} />
      <MenuList>
        <MenuItem><Link cursor='pointer' color='headerText'>Home</Link></MenuItem>
        <MenuItem ><Link cursor='pointer' color='headerText'>About</Link></MenuItem>
        <MenuItem ><Link cursor='pointer' color='headerText'>Roadmap</Link></MenuItem>
        <MenuItem ><Link cursor='pointer' color='headerText'>Tokenomics</Link></MenuItem>
        <MenuItem ><Link cursor='pointer' color='headerText'>Buy $SamoWolf</Link></MenuItem>
      </MenuList>
    </>
  )}
        </Menu>
        </Flex>
        } */}
    </Flex>}
{!isLargerThan1000 && <Mobileheader />}
    </>
  )
}

export default Index