import { ChakraBaseProvider, extendBaseTheme,extendTheme } from '@chakra-ui/react'
// `@chakra-ui/theme` is a part of the base install with `@chakra-ui/react`
import chakraTheme from '@chakra-ui/theme'

// const { Button } = chakraTheme.components
import Text from './components/Text'

export const theme = extendTheme({
  config :{
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  components: {
    Text,
  },
  colors:{
    100:'#5e33d2',
    200:'#422493',
    300:'#932ed2',
    400:'#2e60d2',
    500:'#2e60d2'
  },
  semanticTokens: {
    colors:{
      error: 'red.500',
      headerText:{
        _dark: '#fff',
        _light:'black.50'
      },
      backCol:{
        _dark: 'black.50',
        _light:'#fff'
      }
    }
  }
})