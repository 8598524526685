
// export const oauth_url = "https://discord.com/api/oauth2/authorize?client_id=1172932116598751294&redirect_uri=https%3A%2F%2Fapp.samowolf.com%2Fclaimrole%2F&response_type=code&scope=identify%20email%20connections%20guilds.join%20guilds%20guilds.members.read"
export const oauth_url_other = "https://discord.com/api/oauth2/authorize?client_id=1172932116598751294&redirect_uri=https%3A%2F%2Fsamowolf.xyz%2Fairdrop&response_type=code&scope=identify%20email%20connections%20guilds%20guilds.join%20guilds.members.read"
export const oauth_url_local = "https://discord.com/api/oauth2/authorize?client_id=1172932116598751294&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fairdrop&response_type=code&scope=identify%20email%20connections%20guilds%20guilds.join%20guilds.members.read"
export const oauth_url = getVariableBasedOnLocation(oauth_url_local,oauth_url_other)
export const pub_key = "b084282341d2482731fd31b65e164e9b884a250c72878f9851fed13e64cf2dc7"
export const redirect_uri_local = "http://localhost:3000"
export const redirect_uri_other = "https://samowolf.xyz"
export const redirect_uri = getVariableBasedOnLocation(redirect_uri_local,redirect_uri_other)
export const token = "MTE3MjkzMjExNjU5ODc1MTI5NA.Guefw-.W2rtCeQM47JRUQv47J7RC0YsnfbgQbBnzCVvFs"


function getVariableBasedOnLocation(localUrl,other) {
    // if(!window) return localUrl
    // Check if the hostname is localhost
    if (process.env.NODE_ENV === 'development') {
      // Return variable for localhost
      return localUrl;
    } else {
      // Return variable for other domains
      return other;
    }
  }