import React from 'react'
import {Box,Menu, MenuButton,MenuList,MenuItem,Text,
         useColorMode,IconButton, Link, useMediaQuery} from '@chakra-ui/react';
import {HamburgerIcon,CloseIcon,SunIcon,MoonIcon} from '@chakra-ui/icons'
// import Link from 'next/link'
// import {useRouter} from 'next/router'
import Logo, { LogoText3d } from './Logo';
import { Link as Clink, animateScroll as scroll } from "react-scroll";
// import { Link as Clink, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll' 
function Mobileheader() {
//  const {push:goto} = useRouter() 
 const [isLargerThan1000] = useMediaQuery('(min-width:1000px)')
    const { colorMode, toggleColorMode } = useColorMode();
    const linkBehaviour = {
      activeClass:"linkActive",
    spy:true,
    smooth:true,
    offset:-70,
    duration:500,
}
const isLight = colorMode === 'light'
  return (
    <Box bg='headerbg' h='10vh' w='100vw' display='flex' position='sticky' top='0' zIndex='100' backdropFilter='blur(10px)' justifyContent='space-between' alignItems='center' pl={['5','10','20']} pr={['5','10','20']}>
        <Box display='flex' alignItems='center'>
        <Logo />
        <LogoText3d />
        </Box>
        <Box display='flex' alignItems='static' gap='3'>
        <IconButton onClick={toggleColorMode} icon={colorMode === 'dark' ? <SunIcon color='primary'/> : <MoonIcon color='primary'/>}/>
        <Menu backdropFilter='blur(10px)'>
           {({ isOpen }) => (
    <>
      <MenuButton as={IconButton} isActive={isOpen} icon={isOpen ? <CloseIcon boxSize='25px'/> : <HamburgerIcon boxSize='25px'/>} />
      <MenuList bg={isLight ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.8)'} backdropFilter='blur(10px)' p='5' borderBottomRadius='10px'>
        <MenuItem><Link cursor='pointer'  color='headerText'><Clink to='home' {...linkBehaviour}>Home</Clink></Link></MenuItem>
        <MenuItem ><Link cursor='pointer'  color='headerText'><Clink href='https://app.samowolf.xyz' {...linkBehaviour}>APPs</Clink></Link></MenuItem>
        <MenuItem ><Link cursor='pointer'  color='headerText'><Clink to='about' {...linkBehaviour}>About</Clink></Link></MenuItem>
        <MenuItem ><Link cursor='pointer' color='headerText'><Clink to='roadmap' {...linkBehaviour}>Roadmap</Clink></Link></MenuItem>
        <MenuItem ><Link cursor='pointer'  color='headerText'><Clink to='tokenomics' {...linkBehaviour}>Tokenomics</Clink></Link></MenuItem>    
        <MenuItem ><Link cursor='pointer' href='/airdrop'  color='headerText'>Airdrop</Link></MenuItem>
        <MenuItem ><Link cursor='pointer' href='/presale'  color='headerText'>Presale</Link></MenuItem>
        <MenuItem ><Link cursor='pointer'  color='headerText'><Clink to='howtobuy' {...linkBehaviour}>Buy $SamoWolf</Clink></Link></MenuItem>
      </MenuList>
    </>
  )}
        </Menu>
</Box>
    </Box>
  )
}

export default Mobileheader