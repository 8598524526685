import { Box } from '@chakra-ui/react'
import React from 'react'
import Background from '../Layouts/Background'
import FirstSec from '../Layouts/Body/FirstSec/Index'
import Footer from '../Layouts/Body/Footer/Index'
import SecondSec from '../Layouts/Body/SecondSec'
import Sidebar from '../Layouts/Body/sidebar'
import Index from '../Layouts/Header'
import HomepageArranged from './PagesArrange'

function Homepage() {
  return (
    < >
    <Background />
    <Index />
    <HomepageArranged />
    <Footer />
    <Sidebar />
    </>
  )
}

export default Homepage