import React, { useState } from 'react'
import Index from '../../Layouts/Header'
import { Box, Button, Flex , Input, Text, useMediaQuery} from '@chakra-ui/react';
import { Image3D, LogoText3d } from '../../Layouts/Header/Logo'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import { useMintNFTs } from '../../../hooks/mintnfts';
function Nft() {
  const {connected} = useWallet()
  const [number,setNumber] = useState(0)
  const {mintNfts,tokenTotalSupply,isLoading} = useMintNFTs()
  return (
    <>
    <Index />
    <Flex  w="100vw"  flexDir="column" alignItems="center" justifyContent="center">
      <LogoText3d mwidth={"300px"} mzIndex={5} dzIndex={2.6} dWidth={"600px"} height={"100px"} text={"MINT SAMOWOLFY"}/>
      <Flex justifyContent='space-evenly' borderRadius="10px" gap="10px" flexWrap="wrap">
      <Flex alignItems="center" justifyContent="center" minW="300px">
        {/* <Image  h="500px" w="600px"  src={img} alt="SamoWolf Book cover"/> */}
        <Image3D />
      </Flex>
      <Flex alignItems="center" justifyContent="center" flexDir="column" gap="5" >
        <Text  w="300px">Mint SamoWolfy, The first tokenized AI generated story book on solana, <br />
           First edition of the birth and Journey of SamoWolf. The Birth to the Journey of SamoWolf worth documenting as an NFT</Text>
        <Input fontSize="20px" fontWeight="bold" textAlign="center" display="flex" alignItems="center" justifyContent="center" value={number} onChange={e=>setNumber(e.target.valueAsNumber)} disabled={false} color="#000" onWheel={(e)=>e.target.blur()} 
        type="number" h='40px' w='300px' bg="#fff" borderRadius="10px"/>
        <Flex alignItems="center" w="100%" justifyContent="space-between">
          <Button onClick={e=>setNumber(val=>val !==0?val-1:val)} w="50px" h='50px' display="flex" alignItems="center"
           justifyContent="center" border="1px solid #fff" borderRadius="50%"
            fontSize="25px" fontWeight="bold">-</Button>
            <Text>Cost: {parseFloat(number * 0.05).toFixed(2)}SOL</Text>
          <Button onClick={e=>setNumber(val=>val !==5?val+1:val)} w="50px" h='50px' display="flex" alignItems="center"
           justifyContent="center" border="1px solid #fff" borderRadius="50%" 
           fontSize="25px" fontWeight="bold">+</Button>
        </Flex>
        {connected ? <Flex w="100%" justifyContent="space-between">
          <Button isLoading={isLoading} onClick={e=>mintNfts(number)} _hover={{bg:"#fff",color:"#000"}} border="1px solid #fff" p="2" borderRadius="5px">MINT</Button>
        <WalletMultiButton />
        </Flex> : <WalletMultiButton />}
        <Text>{tokenTotalSupply}/500 NFTs minted</Text>
      </Flex>
      </Flex>
    </Flex>
    </>
  )
}

export default Nft